<template>

  <v-dialog
    :width="modeType==='edit'?600:900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="">
      <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

        <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
          <span>
            <span v-if="modeType=='create'">New Workgroup</span>
            <span v-if="modeType=='edit'">Edit <span class="font-weight-black"> {{ workgroup.name }}</span> Workgroup</span>
          </span>
        </v-card-title>

        <span class="modal-close-btn">
          <v-btn
            icon
            color="grey"
            @click="show=false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </span>

        <v-card-text class="text-center">

          <v-row>

            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Name"
                    :rules="rules.nameRules"
                    v-model="workgroupName"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                    outlined
                    label="Description"
                    :rules="rules.descRules"
                    v-model="workgroupDescription"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="d-flex flex-column align-content-space-between" v-if="modeType==='create'">
              <span class="font-weight-bold text-uppercase text-left mb-4">
                Workgroups members can...
              </span>
              <v-switch
                inset
                label="View workgroup items"
                color="secondary"
                hide-details
                true-value='1'
                false-value='0'
                v-model="workgroupView"
                ref="view"
                :disabled="disabledValue(`view`)"
              ></v-switch>
              <v-switch
                inset
                label="View and edit workgroup items"
                color="secondary"
                hide-details
                true-value='1'
                false-value='0'
                v-model="workgroupEdit"
                ref='edit'
                :disabled="disabledValue(`edit`)"
              ></v-switch>
              <v-switch
                inset
                label="View, edit and delete workgroup items"
                color="secondary"
                hide-details
                true-value='1'
                false-value='0'
                v-model="workgroupDelete"
                ref='delete'
                :disabled="disabledValue(`delete`)"
              ></v-switch>
              <v-switch
                inset
                label="Initially set workgroup as deactivated"
                color="secondary"
                hide-details
                true-value="inactive"
                false-value="active"
                v-model="workgroupInactive"
                class="mt-16"
              ></v-switch>
            </v-col>

          </v-row>

        </v-card-text>

        <v-card-actions class="d-flex align-center justify-center">
          <v-spacer></v-spacer>
          <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
          <v-btn depressed width="130" color="secondary" @click="submit()">
            <span>
              <span v-if="modeType=='create'">Add</span>
              <span v-if="modeType=='edit'">Update</span>
            </span>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'WorkgroupCreateUpdate',
  props: {
    workgroup: {},
    value: {
      required: true,
      type: Boolean
    },
    modeType: {
      required: true,
      type: String
    }
  },
  data: () => ({
    valid: true,
    saving: false,
    form: {
      name: '',
      description: '',
      view: '',
      edit: '',
      delete: '',
      status: ''
    },
    formInitial: {
      name: '',
      description: '',
      view: '0',
      edit: '0',
      delete: '0',
      status: 'active'
    },
    rules: {
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 50) || 'Name must be less than 50 characters'
      ],
      descRules: [
        v => (v || '').length <= 512 || 'Description must be less than 512 characters'
      ]
    },
    switchBehavior: {
      view: { controls: [], disabled: false },
      edit: { controls: ['view'], disabled: false },
      delete: { controls: ['edit', 'view'], disabled: false } // Put the controlled abilities in backward order
    }
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    workgroupName: {
      get () {
        return (this.modeType === 'edit' ? this.workgroup.name : this.formInitial.name)
      },
      set (val) {
        this.form.name = val
      }
    },
    workgroupDescription: {
      get () {
        // return this.workgroup.description
        return (this.modeType === 'edit' ? this.workgroup.description : this.formInitial.description)
      },
      set (val) {
        this.form.description = val
      }
    },
    workgroupView: {
      get () {
        // return this.workgroup.view
        return (this.modeType === 'edit' ? this.workgroup.view : this.formInitial.view)
      },
      set (val) {
        this.form.view = val
      }
    },
    workgroupEdit: {
      get () {
        // return this.workgroup.edit
        return (this.modeType === 'edit' ? this.workgroup.edit : this.formInitial.edit)
      },
      set (val) {
        this.form.edit = val
      }
    },
    workgroupDelete: {
      get () {
        // return this.workgroup.delete
        return (this.modeType === 'edit' ? this.workgroup.delete : this.formInitial.delete)
      },
      set (val) {
        this.form.delete = val
      }
    },
    workgroupInactive: {
      get () {
        // return this.workgroup.inactive
        return (this.modeType === 'edit' ? this.workgroup.status : this.formInitial.status)
      },
      set (val) {
        this.form.status = val
      }
    }
  },
  methods: {
    setInitialFormValues () {
      this.workgroupName = this.formInitial.name
      this.workgroupDescription = this.formInitial.description
      this.workgroupView = this.formInitial.view
      this.workgroupEdit = this.formInitial.edit
      this.workgroupDelete = this.formInitial.delete
      this.workgroupInactive = this.formInitial.status
    },
    removeProps (currentForm) {
      const propsToRemove = ['view', 'edit', 'delete', 'status']

      const newForm = Object.keys(currentForm).reduce((object, key) => {
        if (propsToRemove.indexOf(key) < 0) {
          object[key] = currentForm[key]
        }
        return object
      }, {})

      return newForm
    },
    submit () {
      const formPassedValidation = this.$refs.form.validate()
      if (formPassedValidation === false) {
        return false
      }

      this.saving = true

      const axiosAction = (this.modeType === 'create' || this.modeType === 'duplicate') ? 'post' : 'put'
      const axiosUrl = (this.modeType === 'edit') ? '/api/workgroups/' + this.workgroup.id : '/api/workgroups'

      let newForm

      if (this.modeType === 'edit') {
        newForm = this.removeProps(this.form)
      } else {
        newForm = this.form
      }

      axios({
        method: axiosAction,
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data: newForm
      }).then(() => {
        this.closeModal()
        this.$emit('reload', true)
      }).catch(error => {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      })
    },
    closeModal () {
      this.$refs.form.reset()
      this.show = false
      this.saving = false
    },
    resetModal () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.saving = false
    },
    setSwitches (ability) {
      const parent = this.switchBehavior[ability]

      if (this.form[ability] === '1') {
        Array.prototype.forEach.call(parent.controls, child => {
          this.switchBehavior[child].disabled = true
          this.form[child] = '1'

          // Switch on
          this.formInitial[child] = '1'
        })
      } else {
        // Need to check for presence of a value first
        if (this.switchBehavior[ability].controls.length > 0) {
          this.switchBehavior[this.switchBehavior[ability].controls[0]].disabled = false
        }
      }
    },
    disabledValue (ability) {
      this.setSwitches(ability)
      return this.switchBehavior[ability].disabled
    },
    initializeForm () {
      this.form.view = ''
      this.form.edit = ''
      this.form.delete = ''
      this.form.status = ''
      this.formInitial.view = '0'
      this.formInitial.edit = '0'
      this.formInitial.delete = '0'
      this.switchBehavior.edit.disabled = false
      this.switchBehavior.view.disabled = false
      this.switchBehavior.delete.disabled = false
      this.setInitialFormValues()
    }
  },
  watch: {
    show: function (value) {
      this.form.client_id = this.$store.state.client.activeClient.clientID

      if (value === true && this.modeType === 'edit') {
        this.form.name = this.workgroup.name
        this.form.description = this.workgroup.description
        this.form.client_id = this.$store.state.client.activeClient.clientID

        // remove view, edit, delete, and status
        /*
        Vue.delete(this.form, 'view')
        Vue.delete(this.form, 'edit')
        Vue.delete(this.form, 'delete')
        Vue.delete(this.form, 'status')
        */
      } else if (value === true && this.modeType === 'create') {
        this.resetModal()
        this.initializeForm()
      }
    }
  }
})
</script>
