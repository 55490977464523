<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-briefcase</v-icon>
          <h1 class="darkGrey--text">Workgroups</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Users
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
          @click="openModal('createUpdate', 'create')"
        >
          <v-icon size="18">mdi-plus</v-icon> Add Workgroup
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex mb-6">
      <v-col class="d-flex flex-row">
        <v-checkbox
          color="secondary"
          label="Show deactivated Workgroups"
          v-model="deactivatedWorkgroups"
          hide-details
          class="custom-checkbox-small"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          @keydown="filterList"
          @change="filterList"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="workgroups"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
            :server-items-length="totalWorkgroups"
            :options.sync="options"
            :loading="loading"
            :search="search"
          >
            <template v-slot:item.name="{ item }">
              <span class="py-6 d-flex flex-column">
                <span class="font-weight-bold">
                  {{ item.name }}
                </span>
                <span class="text-caption">
                  {{ item.description }}
                </span>
              </span>
            </template>
            <template v-slot:item.privileges="{ item }">
              <span class="d-flex py-6 mr-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-if="item.view === '1'"
                      class="ma-2 px-1"
                      color="accentGreen white--text"
                      v-bind="attrs"
                      v-on="on"
                    ><v-icon>mdi-eye-check-outline</v-icon>
                    </v-chip>
                  </template>
                  <span>
                    Members can <b>view</b> items assigned to the {{ item.name }} workgroup
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-if="item.edit === '1'"
                      class="ma-2 px-1"
                      color="accentBlue white--text"
                          v-bind="attrs"
                          v-on="on"
                    ><v-icon>mdi-pencil-outline</v-icon>
                    </v-chip>
                  </template>
                  <span>
                    Members can <b>edit</b> items assigned to the {{ item.name }} workgroup
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-if="item.delete === '1'"
                      class="ma-2 px-1"
                      color="accentRed white--text"
                          v-bind="attrs"
                          v-on="on"
                    ><v-icon>mdi-delete-circle-outline</v-icon>
                </v-chip>
                  </template>
                  <span>
                    Members can <b>delete</b> items assigned to the {{ item.name }} workgroup
                  </span>
                </v-tooltip>
              </span>
            </template>
            <template v-slot:item.status="{ item }">
              <v-tooltip top
                  v-if="item.status === 'active'">
                <template v-slot:activator="{on , attrs}">
                  <v-chip
                    class="ma-2 px-1"
                    color="accentYellow darken-2 white--text"
                      v-bind="attrs"
                      v-on="on"
                  ><v-icon>mdi-shield-star-outline</v-icon>
                  </v-chip>
                </template>
                  <span>
                   The <b>{{ item.name }}</b> workgroup is active
                  </span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex flex-row justify-center">

                <v-menu transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(itemMenu, index) in itemsMenuFiltered (item)"
                        :key="index"
                        @click="openModal(itemMenu.actions, itemMenu.optional, item)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="itemMenu.type? itemMenu.type:'darkGrey'"
                          >
                            {{ itemMenu.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                          >
                            {{ itemMenu.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <workgroup-create-update @reload="getDataFromApi"  v-model="modals.createUpdate" :modeType="modeType" :workgroup="workgroup"></workgroup-create-update>
    <workgroup-members @reload="getDataFromApi" v-model="modals.members" :workgroup="workgroup"></workgroup-members>
    <workgroup-member-abilities @reload="getDataFromApi" v-model="modals.memberAbilities" :workgroup="workgroup"></workgroup-member-abilities>
    <workgroup-activate @reload="getDataFromApi" v-model="modals.activate" :workgroup="workgroup"></workgroup-activate>
    <workgroup-deactivate @reload="getDataFromApi" v-model="modals.deactivate" :workgroup="workgroup"></workgroup-deactivate>
    <workgroup-delete @reload="getDataFromApi" v-model="modals.delete" :workgroup="workgroup"></workgroup-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import WorkgroupCreateUpdate from '@/components/Workgroups/WorkgroupCreateUpdate'
import WorkgroupMembers from '@/components/Workgroups/WorkgroupMembers'
import WorkgroupMemberAbilities from '@/components/Workgroups/WorkgroupMemberAbilities'
import WorkgroupActivate from '@/components/Workgroups/WorkgroupActivate'
import WorkgroupDeactivate from '@/components/Workgroups/WorkgroupDeactivate'
import WorkgroupDelete from '@/components/Workgroups/WorkgroupDelete'
import Workgroup from '@/models/Workgroup'

export default Vue.extend({
  name: 'Workgroups',
  components: {
    Portal,
    WorkgroupCreateUpdate,
    WorkgroupMembers,
    WorkgroupMemberAbilities,
    WorkgroupActivate,
    WorkgroupDeactivate,
    WorkgroupDelete
  },
  data: () => ({
    modeType: '',
    workgroup: {
      name: '',
      description: '',
      clientId: '',
      view: '',
      edit: '',
      delete: '',
      status: '',
      workgroupId: 0
    },
    workgroups: [],
    totalWorkgroups: 0,
    loading: false,
    deactivatedWorkgroups: false,
    itemContent: {},
    modals: {
      createUpdate: false,
      delete: false,
      activate: false,
      deactivate: false,
      memberAbilities: false,
      members: false
    },
    breadcrumbsItems: [
      {
        text: 'Admin Panel',
        disabled: false,
        href: ''
      },
      {
        text: 'Workgroups',
        disabled: true,
        href: ''
      }
    ],
    itemsMenu: [
      { icon: 'mdi-pencil', text: 'Edit', actions: 'createUpdate', optional: 'edit' },
      { icon: 'mdi-account-multiple', text: 'Manage Members', actions: 'members' },
      { icon: 'mdi-format-list-checks', text: 'Manage Members Abilities', actions: 'memberAbilities' },
      { icon: 'mdi-check-circle-outline', text: 'Activate', actions: 'activate' },
      { icon: 'mdi-close-circle-outline', text: 'Deactivate', type: 'important', actions: 'deactivate' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    headers: [
      { text: 'Name', width: '500px', value: 'name' },
      { text: 'Members can', width: '200px', value: 'privileges', sortable: false },
      { text: '', width: '50px', value: 'status', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, width: '150px', align: 'center' }
    ],
    workgroupsSample: [
      { name: 'Management Team', description: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi eu mi dolor', privileges: 'View workgroup items', status: 'active' },
      { name: 'Planning Department', description: 'In vitae Leo sem. Nam nec rutrum elit. Donec at volutpat quam.', privileges: 'View, edit and delete workgroup items', status: 'active' },
      { name: 'Public Works Department', description: 'Donec varius, ligula vitae porta sollicitudin, magna enim vehicula justo, ut elementum nibh eros suscipit nulla. ', privileges: 'View and edit workgroup items', status: 'active' }
    ],
    boardsSample: ['City Manager Office', 'City Secretary Office'],
    search: '',
    minimumSearchLength: 3,
    searchWait: 1000, // in milliseconds
    options: {},
    canSearchNow: false
  }),
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    },
    deactivatedWorkgroups () {
      this.getDataFromApi()
    },
    canSearchNow () {
      this.getDataFromApi()
    }
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'client/setActiveClient') {
        if (state.activeClient !== null) {
          this.getDataFromApi()
        }
      }
    })
  },
  beforeDestroy () {
    this.unsubscribe()
  },
  mounted () {
    this.options = {
      page: 1,
      itemsPerPage: 10
    }
    this.getDataFromApi()
  },
  computed: {
    workgroupStatus () {
      return (this.deactivatedWorkgroups) ? '' : 'active'
    }
  },
  methods: {
    getDataFromApi () {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      let orderBy = null
      if (sortBy !== undefined && sortBy[0] !== undefined) {
        const sortDir = sortDesc[0] ? '-' : ''
        orderBy = sortDir + sortBy[0]
      }

      Workgroup
        .page(page)
        .where('name', this.search)
        .where('status', this.workgroupStatus)
        .where('client_id', this.$store.state.client.activeClient.clientID)
        .orderBy(orderBy)
        .get()
        .then(response => {
          this.workgroups = response.data
          this.totalWorkgroups = response.total
          this.loading = false
          this.workgroups.forEach(elm => {
            elm.active = (elm.status === 'active')
            elm.inactive = (!elm.status === 'active')
          })
        })
    },
    itemsMenuFiltered (item) {
      const itemsToRemove = []

      if (item.status === 'active') {
        itemsToRemove.push('activate')
      }

      if (item.status === 'inactive') {
        itemsToRemove.push('deactivate')
      }

      return this.itemsMenu.filter(function (el) { return !itemsToRemove.includes(el.actions) })
    },
    filterList () {
      // working for most, but not when deleting all after > minimum
      if (!this.search.length) {
        this.canSearchNow = true
        // this.canSearchNow = false
      } else if (this.search.length === this.minimumSearchLength) {
        this.canSearchNow = true
        // this.canSearchNow = false
      } else if (this.search.length > this.minimumSearchLength) {
        if (!this.canSearchNow) {
          setTimeout(() => {
            this.canSearchNow = true
          }, this.searchWait)
        } else {
          this.canSearchNow = false
        }
      }
    },
    openModal (item, optional, workgroup) {
      if (optional) this.modeType = optional
      this.modals[item] = true
      if (workgroup) this.workgroup = workgroup
    }
  }
})
</script>
