<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Manage Member Abilities of</span>
        <span><span class="font-weight-black">{{ workgroup.name }}</span> workgroup</span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text class="text-center">

        <v-row>
          <v-col class="d-flex flex-column align-content-space-between">
            <span class="font-weight-bold text-uppercase text-left mb-4">
              Workgroups members can...
            </span>
            <v-alert
              v-if="updateSuccess===true"
              dense
              text
              class="text-left"
              type="success"
            >
              Member abilities updated for the {{ workgroup.name }} workgroup
            </v-alert>
            <v-switch
              inset
              label="View workgroup items"
              color="secondary"
              hide-details
              true-value=1
              false-value=0
              v-model=workgroup.view
              :disabled="disabledValue(`view`)"
              value=1
              @click="updateMemberAbilities('view')"
            ></v-switch>
            <v-switch
              inset
              label="View and edit workgroup items"
              color="secondary"
              hide-details
              true-value=1
              false-value=0
              v-model=workgroup.edit
              :disabled="disabledValue(`edit`)"
              value=1
              @click="updateMemberAbilities(`edit`)"
            ></v-switch>
            <v-switch
              inset
              label="View, edit and delete workgroup items"
              color="secondary"
              hide-details
              true-value=1
              false-value=0
              v-model=workgroup.delete
              :disabled="disabledValue(`delete`)"
              value=1
              @click="updateMemberAbilities(`delete`)"
            ></v-switch>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Close</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'WorkgroupMemberAbilities',
  props: {
    workgroup: {},
    workgroupId: {
      required: false,
      type: Number
    },
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    form: {
      formAction: 'manageAbilities' // need to be equal to value in backend and need to take this out before performing update
    },
    switchBehavior: {
      view: { controls: [], disabled: false },
      edit: { controls: ['view'], disabled: false },
      delete: { controls: ['edit', 'view'], disabled: false } // Put the controlled abilities in backward order
    },
    saving: false,
    updateSuccess: false
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    updateMemberAbilities (ability) {
      this.setSwitches(ability)
      this.form.view = this.workgroup.view
      this.form.edit = this.workgroup.edit
      this.form.delete = this.workgroup.delete
      this.saving = true
      axios.put(process.env.VUE_APP_BACKEND_URL + '/api/workgroups/' + this.workgroup.id, this.form)
        .then((response) => {
          this.saving = false
          this.$emit('reload', true)
          if (response.data.status === 'success') {
            // Displaying the message inside the modal. Ask Chad about temporarily supressing the standard alert.
            this.showUpdateSuccess()
          }
        })
    },
    showUpdateSuccess () {
      this.updateSuccess = true
      setTimeout(() => { this.updateSuccess = false }, 2000)
    },
    setSwitches (ability) {
      const parent = this.switchBehavior[ability]

      if (this.workgroup[ability] === '1') {
        Array.prototype.forEach.call(parent.controls, child => {
          this.switchBehavior[child].disabled = true
          this.workgroup[child] = '1'
        })
      } else {
        // Need to check for presence of a value first
        if (this.switchBehavior[ability].controls.length > 0) {
          this.switchBehavior[this.switchBehavior[ability].controls[0]].disabled = false
        }
      }
    },
    disabledValue (ability) {
      this.setSwitches(ability)
      return this.switchBehavior[ability].disabled
    }
  }
})
</script>
